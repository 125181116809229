import React, {useState, useEffect, Suspense} from 'react';

function DeviceDetector(){
  const [isMobile, setIsMobile] = useState(window.innerWidth < 680);
  const DesktopRouter = React.lazy(() => import('../Desktop/Router'));
  const MobileRouter = React.lazy(() => import('../Mobile/Router'));

  function handleWindowSizeChange() {
    if(window.innerWidth < 680){
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [isMobile]);

  return <div>
    <Suspense fallback={null}>
      {isMobile && <MobileRouter />}
      {!isMobile && <DesktopRouter />}
      </Suspense>
    </div>
}

export default DeviceDetector;