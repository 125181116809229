import React from 'react';
import DeviceDetector from './components/Shared/DeviceDetector';
import ReactDOM from 'react-dom/client';
import {UserProvider} from "./components/Shared/Contexts/UserContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <React.StrictMode>
      <DeviceDetector />
    </React.StrictMode>
  </UserProvider>
);