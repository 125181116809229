import React, {createContext, useState, useContext, useEffect} from 'react';

// create the context
export const UserContext = createContext(null);

// create a provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() =>{    
    const storedUser = localStorage.getItem('user');
    if(storedUser){
      setUser(JSON.parse(storedUser));
    }
  }, [])
  
  // this function could be called after a successful login
  const logIn = (userData) => {    
    setUser(userData);
  };

  // this function could be called to log out
  const logOut = () => {    
    setUser(null);
  };
  
  
  return (
    <UserContext.Provider value={{ user, logIn, logOut }}>
      {children}
    </UserContext.Provider>
    );
};

// create a custom hook for using the user context
export const useUser = () => {  
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};
